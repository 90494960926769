import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { menuInterface } from 'src/app/Model/Seguridad/menu.Interface';

@Injectable({
  providedIn: 'root'
})
export class NewSidemeduFacadeService {

  public menus: any[] = [
    {
      idMenu: 12,
      descripcion: "Información General",
      texto: "Información General",
      idPadre: 0,
      icono: "fas fa-shopping-cart",
      url: "",
      visible: true,
      activo: true,
      subItems: true,
      idModulo: 1003,
      hijos: [
        {
          idMenu: 17,
          idPadre: 12,
          texto: "Expediente",
          descripcion: "Expediente",
          icono: "fas fa-id-card-alt",
          url: "afiliados/expediente",
          visible: true,
          activo: true,
          subItems: true,
        },
        // {
        //   idMenu: 13,
        //   idPadre: 12,
        //   texto: "Estado Cuenta",
        //   descripcion:"Estado Cuenta",
        //   icono: "fas fa-file-invoice-dollar",
        //   url: "afiliados/estado-cuenta",
        //   visible: true,
        //   activo: true,
        //   subItems: true,
        // },

        // {
        //   idMenu: 14,
        //   idPadre: 12,
        //   texto: "Pagos Realizados",
        //   descripcion:"Pagos Realizados",
        //   icono: "fas fa-money-check-alt",
        //   url: "afiliados/posteo-pagos",
        //   visible: true,
        //   activo: true,
        //   subItems: true,
        // },
        // {
        //   idMenu: 14,
        //   idPadre: 12,
        //   texto: "Solicitudes",
        //   descripcion:"Solicitudes",
        //   icono: "fas fa-list",
        //   url: "afiliados/solicitudes",
        //   visible: true,
        //   activo: true,
        //   subItems: true,
        // }
        
       
      ],
    }
    
    // {
    //   idMenu: 50,
    //   descripcion: "Medios de Pago",
    //   texto: "Medios de Pago",
    //   idPadre: 0,
    //   icono: "fas fa-shopping-cart",
    //   url: "",
    //   visible: true,
    //   activo: true,
    //   subItems: true,
    //   idModulo: 1003,
    //   hijos: [
    //     {
    //       idMenu: 51,
    //       idPadre: 50,
    //       texto: "Deposito o Transf.",
    //       descripcion: "Deposito o Transf.",
    //       icono: "fas fa-project-diagram",
    //       url: "afiliados/registrar-pago",
    //       visible: true,
    //       activo: true,
    //       subItems: true,
    //     },
    //     {
    //       idMenu: 52,
    //       idPadre: 50,
    //       texto: "Débito Automático",
    //       descripcion: "Listado Pagos",
    //       icono: "fas fa-credit-card",
    //       url: "afiliados/mediosPago",
    //       visible: true,
    //       activo: true,
    //       subItems: true,
    //     },
        

       
    //   ],
    // }
  ];

  private Menus$ = new BehaviorSubject<menuInterface[]>([]);
  public responseMenus$: Observable<menuInterface[]> = this.Menus$.asObservable();

  constructor() { }

  consumirMenus() {
    this.Menus$.next(this.menus)
    // let menus: any[] = [];
    // menus = JSON.parse(localStorage.getItem('menus'));
    // this.Menus$.next(menus);
  }

  setearMenusFiltrado(item) {
    this.Menus$.next(item);
    localStorage.setItem("menus", JSON.stringify(item));
  }
}
