import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { DataApi } from '../Model/dataApi';
import { param, when } from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class DataApiService {

  constructor(private http: HttpClient) { }


  GetDataApi(url: string, params: any, module?: number) {


    /*  const options = {
       headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
       body: params
     }; */
    //    return this.http.get<DataApi>(environment.apiUrl + url, options);

    return this.http.get<DataApi>(this.urlModule(module) + url + params);
  };

  GetDataApiMoodle(url: string, params: any) {
 

    /*  const options = {
       headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
       body: params
     }; */
     //    return this.http.get<DataApi>(environment.apiUrl + url, options);
     
     return this.http.get<DataApi>(url+  params);
   };


  GetDataApiAsync(url: string, params: any, module?: number) {

    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    //    return this.http.get<DataApi>(environment.apiUrl + url, options);
    return this.http.get<DataApi>(this.urlModule(module) + url + params).toPromise();
  };
  /*   return this.http.get<DataApi>(environment.apiUrl + url + params);
  } */

  PostDataApi(url: string, params: any, module?: number) {
    return this.http.post<DataApi>(this.urlModule(module) + url, params);
  };
  PostDataApi1(url: string, params: any, module?: number) {
    return this.http.post<DataApi>(url, params);
  };
  DeleteDataApi(url: string, params: any, module?: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.delete<DataApi>(this.urlModule(module) + url, options);
  }
  DeleteDataApiUrl(url: string, params: any, module?: number) {

    return this.http.delete<DataApi>(this.urlModule(module) + url + params);
  }

  PutDataApi(url: string, params: any, module?: number) {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', }),
      body: params
    };
    return this.http.put<DataApi>(this.urlModule(module) + url, params);
  }

  urlModule(module: number): string {
    var text = '';
    switch (module) {
      case 1: {
        //statements; 
        text = environment.apiUrlConfig;
        break;
      }
      case 2: {
        text = environment.apiUrlVentas;
        break;
      }
      case 3: {
        text = environment.apiUrlCompras;
        break;
      }
      case 4: {
        text = environment.apiUrlUtils;
        break;
      }
      case 5: {
        text = environment.apiUrlInventario;
        break;
      }
      case 6: {
        text = environment.apiUrlRRHH;
        break;
      }
      case 7: {
        text = environment.apiUrlSecurity;
        break;
      }
      case 8: {
        text = environment.apiUrlAfiliados;
        break;
      }
      case 9: {
        text = environment.apiUrlNotificaciones;
        break;
      }

      case 10: {
        text = environment.apiUrlContabilidad;
        break;
      }
      case 11: {
        text = environment.apiUrlDashboard;
        break;
      }
      case 12: {
        text = environment.apiUrlCuentasPorCobrar;
        break;
      }

      case 13: {
        text = environment.apiUrlHospital;
        break;

      }

      case 14: {
        text = environment.apiUrlCreditos;
        break;

      }
      case 15: {
        text = environment.apiUrlPagos;
        break;

      }

      case 16: {
        text = environment.apiUrlEncuesta;
        break;

      }

    }
    return text;
  }
}
