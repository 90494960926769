import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SidemeduFacadeService } from "../side-menu/sidemedu-facade.service";
import { NewSidemeduFacadeService } from "./newsidemedu-facade.service";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}


@Component({
  selector: "app-nuevo-side",
  templateUrl: "./nuevo-side.component.html",
  styleUrls: ["./nuevo-side.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NuevoSideComponent implements OnInit {
  public usuario:any;
 
  constructor(public sideMenuFacade:NewSidemeduFacadeService) {
    this.sideMenuFacade.consumirMenus();
    this.usuario = JSON.parse(localStorage.getItem("data_go504"));

  }

  ngOnInit(): void {
    
  }
}
