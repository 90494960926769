<div class="scrollbar" id="style-6">
    <div class="headerAction">
        <div class="logoAction">
            <!-- <img src="../../../assets/images/Objeto-inteligente-vectorial.png" alt="Logo" class="img-fluid"> -->
        </div>
        <div class="homeAction" style="display: flex; justify-content: flex-end;">
            <!-- <button mat-mini-fab [routerLink]="['/mosaicMenu']" style="background-color: transparent; color: currentColor; opacity: .8;"><mat-icon>home</mat-icon></button> -->
        </div>
    </div>
    <div class="profileSide">
        <div class="imgDivProfile">
            <img src="./assets/images/users/user.jpg" alt="Image Profile" class="imgProfile">
        </div>
        <div class="infoProfile">
            <h6 class="titleInfoProfile">{{usuario?.Nombre}}</h6>
            <p class="subTitleInfoProfile">{{usuario?.TipoUsuario}}</p>
        </div>
    </div>
    <div class="mt-2 items">
        <mat-list *ngFor="let father of (sideMenuFacade.responseMenus$ | async)">
            <div mat-subheader style="margin-bottom: 0px !important;">{{father.texto}}</div>
            <!-- <p style="margin: calc((3rem - 1.5rem)/2) 16px; margin-top: 0px !important;">{{father?.descripcion}}</p> -->
            <mat-list-item  [routerLinkActive]="['is-active']" *ngFor="let children of father.hijos" [routerLink]="children.url">
                <i mat-list-icon class="{{children?.icono}}"></i>
                <div mat-line>{{children.texto}}</div>
            </mat-list-item>
        </mat-list>

        <!-- <div class="text-center" style="margin-top: 25%;">
            <button id="snavButton" mat-mini-fab class="buttonSecundary" [routerLink]="['/mosaicMenu']">
                <mat-icon>
                    home
                </mat-icon>
            </button>
        </div> -->


    </div>



</div>